.steps {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-top: 50px;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #ced1d6;
    top: 24px;
  }
  > a {
    position: relative;
    color: #bbb;
    &:hover {
      text-decoration: none;
    }
    &::before {
      content: attr(data-step);
      position: absolute;
      width: 50px;
      height: 50px;
      border: 4px solid #ced1d6;
      background-color: #fff;
      border-radius: 50%;
      top: -50px;
      left: calc(50% - 25px);
      text-align: center;
      font-weight: bold;
      padding-top: 5px;
      font-size: 1.25rem;
    }
    &.done {
      &::before {
        content: '\2714';
        text-align: center;
        border-color: $success;
        color: $success;
      }
    }
    &.current {
      color: $success;
      font-weight: bold;
      &::before {
        text-align: center;
        border-color: $success;
        color: $success;
      }
    }
    &:first-child::before {
      left: 0;
    }
    &:last-child::before {
      left: auto;
      right: 0;
    }
  }
}
