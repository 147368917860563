.has-badge {
  position: relative;
  > .badge {
    position: absolute;
    top: -4px;
    right: -4px;
  }
}
.badge.has-icon {
  display: inline-flex;
  align-items: center;
  > i {
    font-size: inherit;
  }
}
