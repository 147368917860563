#menuModal .modal-dialog {
  max-width: $sidebar-max-width;
}
.menu {
  margin-bottom: 0;
  @include list-unstyled; // unstyled menu
  ul {
    @include list-unstyled; // also unstyled all submenus
    font-size: .9375rem;
  }
  a {
    display: block; // make all links as blocks
    &:hover {
      text-decoration: none;
    }
    &:focus {
      outline: 0;
    }
  }
  .material-icons { // material-icons
    margin-right: .75rem;
  }
  .has-badge {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .badge {
      position: static;
    }
  }
  // first level menu
  > li {
    > a {
      display: flex;
      align-items: center;
      padding: .625rem 1rem;
      color: $sidebar-link-color;
      &:hover {
        background-color: $sidebar-link-hover-bg;
      }
    }
    &.mm-active {
      background-color: $sidebar-link-active-bg;
      box-shadow: 4px 0 0 0 $sidebar-link-active-mark-bg inset;
      > a {
        background-color: $sidebar-link-active-bg;
        box-shadow: 4px 0 0 0 $sidebar-link-active-mark-bg inset;
      }
    }
    // second level menu
    > ul > li {
      > a {
        padding: .5rem 1rem .5rem 3.25rem;
        color: $sidebar-second-link-color;
        &:hover {
          color: $primary;
        }
      }
      &.mm-active > a {
        position: relative;
        color: $primary;
        &::before {
          position: absolute;
          top: 50%;
          left: 25px;
          width: 7px;
          height: 7px;
          margin-top: -3.5px;
          content: '';
          border-radius: 50%;
          background-color: $primary;
        }
      }
      // Third level menu
      > ul > li {
        > a {
          padding: .5rem 1rem .5rem 4rem;
          color: $sidebar-third-link-color;
          &:hover {
            color: $primary;
          }
        }
        &.mm-active > a {
          color: $primary;
        }
      }
    }
  }
}

.list-tree {
  margin-bottom: 0;
  @include list-unstyled; // unstyled menu
  a {
    display: block; // make all links as blocks
    padding: .25rem .75rem .25rem 1.5rem;
    &:not(:hover) {
      color: $body-color;
    }
    &:hover {
      font-weight: bold;
      text-decoration: none;
    }
  }
  .has-arrow::after {
    left: 0;
    transform: rotate(135deg) translateY(-50%);
  }
  .mm-active > a {
    color: $primary;
    font-weight: bold;
    &.has-arrow::after {
      transform: rotate(225deg) translateY(0);
    }
  }
  ul {
    @include list-unstyled; // also unstyled all submenus
    margin-left: 1.5rem;
    box-shadow: 1px 0 0 0 $border-color inset;
    > li > a {
      position: relative;
      padding-left: 1.25rem;
      &::before {
        content: '';
        position: absolute;
        width: 8px;
        height: 1px;
        background-color: $border-color;
        left: 0;
        top: 49%;
      }
    }
  }
}