// Using dropdown style for typeahead.js
.twitter-typeahead {
  width: 100%;
  .tt-hint {
    color: rgba($text-muted, .7);
  }
  .tt-menu {
    z-index: $zindex-dropdown !important;
    float: left;
    width: 100%;
    font-size: .875rem;
    color: $body-color;
    background-color: $dropdown-bg;
    background-clip: padding-box;
    border: $dropdown-border-width solid $dropdown-border-color;
    @include border-radius($dropdown-border-radius);
    @include box-shadow($dropdown-box-shadow);
    @extend .dropdown-menu;
  }
  .tt-suggestion {
    display: block;
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    clear: both;
    color: $dropdown-link-color;
    white-space: normal;
    @include hover-focus {
      color: $dropdown-link-hover-color;
      text-decoration: none;
      @include gradient-bg($dropdown-link-hover-bg);
    }
  }
  .tt-suggestion.tt-cursor {
    color: $dropdown-link-hover-color;
    text-decoration: none;
    @include gradient-bg($dropdown-link-hover-bg);
  }
}
