// Styling for large devices only, for small devices better use native scrollbar
@include media-breakpoint-up(lg) {
  html {
    scrollbar-width: thin; // Firefox only
  }
  /* Size */
  ::-webkit-scrollbar {
    width: $webkit-scrollbar-size;
    &:horizontal {
      height: $webkit-scrollbar-size;
    }
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: $webkit-scrollbar-track-bg;
    &:vertical {
      border-left: $webkit-scrollbar-track-border;
    }
    &:horizontal {
      border-top: $webkit-scrollbar-track-border;
    }
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $webkit-scrollbar-handle-bg;
    &:vertical {
      border-left: $webkit-scrollbar-handle-border;
    }
    &:horizontal {
      border-top: $webkit-scrollbar-handle-border;
    }
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $webkit-scrollbar-handle-hover-bg;
    &:vertical {
      border-left: $webkit-scrollbar-handle-hover-border;
    }
    &:horizontal {
      border-top: $webkit-scrollbar-handle-hover-border;
    }
  }
}
