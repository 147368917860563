// Limit container-fluid width
.container-fluid {
  max-width: $container-fluid-max-width;
}

// Additional Gutters
.gutters-1 {
  margin-right: -.125rem;
  margin-left: -.125rem;
  > .col,
  > [class*="col-"] {
    padding-right: .125rem;
    padding-left: .125rem;
  }
}
.gutters-2 {
  margin-right: -.25rem;
  margin-left: -.25rem;
  > .col,
  > [class*="col-"] {
    padding-right: .25rem;
    padding-left: .25rem;
  }
}
.gutters-3 {
  margin-right: -.5rem;
  margin-left: -.5rem;
  > .col,
  > [class*="col-"] {
    padding-right: .5rem;
    padding-left: .5rem;
  }
}

// grid
.d-grid {
  display: grid;
}
@for $i from 1 through $grid-columns {
  .grid-template-col-#{$i} {
    grid-template-columns: repeat(#{$i}, 1fr);
  }
  .grid-template-row-#{$i} {
    grid-template-rows: repeat(#{$i}, 1fr);
  }
  .grid-column-start-#{$i} {
    grid-column-start: $i;
  }
  .grid-column-end-#{$i} {
    grid-column-end: $i;
  }
  .grid-row-start-#{$i} {
    grid-row-start: $i;
  }
  .grid-row-end-#{$i} {
    grid-row-end: $i;
  }
}
.grid-column-end-#{($grid-columns + 1)} {
  grid-column-end: $grid-columns + 1;
}
.grid-row-end-#{($grid-columns + 1)} {
  grid-row-end: $grid-columns + 1;
}
.grid-template-col-none {
  grid-template-columns: none;
}
.grid-template-row-none {
  grid-template-rows: none;
}
@each $size, $length in $spacers {
  .grid-gap-#{$size} {
    grid-gap: $length;
  }
  .row-gap-#{$size} {
    row-gap: $length;
  }
  .column-gap-#{$size} {
    column-gap: $length;
  }
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    @if $breakpoint != 'xs' {
      .d-#{$breakpoint}-grid {
        display: grid;
      }
      .grid-template-col-#{$breakpoint}-none {
        grid-template-columns: none;
      }
      .grid-template-row-#{$breakpoint}-none {
        grid-template-rows: none;
      }
      @for $i from 1 through $grid-columns {
        .grid-template-col-#{$breakpoint}-#{$i} {
          grid-template-columns: repeat(#{$i}, 1fr);
        }
        .grid-template-row-#{$breakpoint}-#{$i} {
          grid-template-rows: repeat(#{$i}, 1fr);
        }
        .grid-column-start-#{$breakpoint}-#{$i} {
          grid-column-start: $i;
        }
        .grid-column-end-#{$breakpoint}-#{$i} {
          grid-column-end: $i;
        }
        .grid-row-start-#{$breakpoint}-#{$i} {
          grid-row-start: $i;
        }
        .grid-row-end-#{$breakpoint}-#{$i} {
          grid-row-end: $i;
        }
      }
      .grid-column-end-#{$breakpoint}-#{($grid-columns + 1)} {
        grid-column-end: $grid-columns + 1;
      }
      .grid-row-end-#{$breakpoint}-#{($grid-columns + 1)} {
        grid-row-end: $grid-columns + 1;
      }
      @each $size, $length in $spacers {
        .grid-gap-#{$breakpoint}-#{$size} {
          grid-gap: $length;
        }
        .row-gap-#{$breakpoint}-#{$size} {
          row-gap: $length;
        }
        .column-gap-#{$breakpoint}-#{$size} {
          column-gap: $length;
        }
      }
    }
  }
}

